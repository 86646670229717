import React, { ChangeEvent, SyntheticEvent } from 'react';
import './Navbar.css';

interface IProps {

}

interface IState {
    value: string
}

class Navbar extends React.Component<IProps,IState> {
    constructor(props:IProps) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event:ChangeEvent<HTMLInputElement>) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event:SyntheticEvent) {
        window.open("https://www.microstrategy.com/en/search-results#q=" + this.state.value + "&t=Community&sort=relevancy")
        event.preventDefault();
    }

    render() {
        return (
            <div className="navbar">
                <div id="logo">
                    <a href="https://www.microstrategy.com" >
                        <img src="./Images/microstrategy-logo_small.png" alt="MicroStrategy Logo" id="logo-img-small" />                   
                        <img src="./Images/microstrategy-logo_red.svg" alt="MicroStrategy Logo" id="logo-img" />                   
                    </a>
                    <span id="title">Developer</span>
                </div>
                <form onSubmit={this.handleSubmit} id="search">
                    <input type='text' placeholder="Search MicroStrategy Community" value={this.state.value} onChange={this.handleChange} id="search-bar" />
                </form>
                <button className="btn-talk-to-expert" onClick={function () { window.location.href = "https://www.microstrategy.com/en/support?now=expert" }} id="talk-to-expert">
                    Talk to an expert
                </button>
            </div>
        );
    }
}

export default Navbar
