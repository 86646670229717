import React from 'react'
import './SectionHeader.css'

interface SectionHeaderProps {
    name:string,
    desc:string
}

function SectionHeader(props:SectionHeaderProps) {
    return (
        <div className="section-header">
            <h1 className="section-header-name">{props.name}</h1>
            <h3 className="section-desc">{props.desc}</h3>
        </div>
    )
}

export default SectionHeader
