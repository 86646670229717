import React from 'react'
import './MoreButton.css'

interface ButtonProps {
    link:string,
    name:string
}

function MoreButton(props:ButtonProps) {
    return (
        <button className="card" id="more" onClick={function() {window.location.href = props.link}}>
            {props.name} &#8594;
        </button>
    )
}

export default MoreButton
