export const courses:{name:string,link:string,src:string}[] =
[
    {
        "name": "Developer (DVL) Certification",
        "link": "https://www.microstrategy.com/education/course?Certification=5506113",
        "src": "DVL-lg.png",
    },
    {
        "name": "Getting started with MicroStrategy SDKs",
        "link": "https://www.microstrategy.com/education/course?Course%20Recording=8463237&Instructor%20Led=6134865",
        "src": "sdk-lg.png",
    },
    {
        "name": "Leveraging the REST API for MicroStrategy workflows",
        "link": "https://www.microstrategy.com/education/course?Course%20Recording=8463871&Instructor%20Led=6134866",
        "src": "rest-lg.png",
    }
]