export const sdk:{name:string,link:string,desc:string,color:string}[] =
    [
        {
            "name": "REST API",
            "link": "https://microstrategy.github.io/rest-api-docs/",
            "desc" : "The MicroStrategy REST API is designed to help developers build data-driven client applications quickly and easily. ",
            "color": "red"
        },
        {
            "name": "REST API Playground",
            "link": "https://github.com/MicroStrategy/rest-api-playground",
            "desc" : "A place you can see live demos of our REST API, API specifications, and code examples. You can also play with it without writing a single line of code.",
            "color": "red"
        },        
        {
            "name": "Embedding SDK",
            "link": "https://microstrategy.github.io/embedding-sdk-docs/",
            "desc": "MicroStrategy Embedding SDK allows you to quickly integrate a MicroStrategy dossier into a web application in a responsive manner. ",
            "color": "orange"
        },
        {
            "name": "Embedding Playground",
            "link": "https://playground.microstrategy.com",
            "desc": "The one-stop shop for developers to build impactful, interactive analytics experiences through drag-and-drop interface and built-in code editor.",
            "color": "orange"
        },        
        {
            "name": "Python SDK",
            "link": "https://github.com/MicroStrategy/mstrio-py",
            "desc": "A high-level interface for Python to give data scientists, developers, and administrators simple and secure access to their MicroStrategy environment.",
            "color": "green"
        },
        {
            "name": "Hyper SDK",
            "link": "https://microstrategy.github.io/hyper-sdk/",
            "desc": "A JavaScript SDK that allows you to quickly add HyperIntelligence to your own websites.",
            "color": "green"
        },        
        {
            "name": "Mobile SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/MobileSDK/Default.htm",
            "color": "purple",
            "desc": "Customize your Android or iOS mobile applications built using MicroStrategy Mobile App Platform",
        },
        {
            "name": "Visualization SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/VisSDK/Default.htm",
            "color": "pink",
            "desc": "Create new stunning visualization plugins with the Custom Visualization Tool",
        },
        {
            "name": "Library SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/LibrarySDK/Default.htm",
            "color": "lightblue",
            "desc": "Access and collaborate on all your MicroStrategy dossiers in one easy, centralized location",
        },
        {
            "name": "Data Connector SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/DataConnectorSDK/Default.htm",
            "color": "blue",
            "desc": "Define custom web connections to explore fresh data with your MicroStrategy analytics",
        },
        {
            "name": "Library Mobile SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/LibraryMobileSDK/Default.htm",
            "color": "green",
            "desc": "Access and collaborate on all your MicroStrategy dossiers from your own mobile device",
        },
        {
            "name": "Web SDK",
            "link": "https://www2.microstrategy.com/producthelp/Current/WebSDK/Default.htm",
            "color": "brown",
            "desc": "Harness the powerful MicroStrategy Web product with simple and intuitive customizations",
        }
    ]
