import React from 'react';
import './App.css';
import { sdk } from './data/sdk';
import { courses } from './data/courses';
import Navbar from './Components/Navbar';
import SDKCard from './Components/SDKCard';
import SectionHeader from './Components/SectionHeader';
import CourseCard from './Components/CourseCard';
import MoreButton from './Components/MoreButton';
import Footer from './Components/Footer'

function App() {
  const whatsnew = "https://www.microstrategy.com/enterprise-analytics/whats-new"; 
  return (
    <div className="whole">
      <div className="headers">
        <Navbar />
        <div className="banner">
          <div className="welcome">
            <h1 id="headerwelcome">Welcome to MicroStrategy Developer</h1>
            <button onClick={function () { window.open(whatsnew, "_blank") }} id="whatsnew">
              See What's New in MicroStrategy &#8594;
            </button>
          </div>
          <div className="video">
            <iframe title="Introduction to MicroStrategy"
              src="https://www.youtube.com/embed/QKtIG0MlfAk">
            </iframe>
          </div>
        </div>
      </div>
      <div className="app">
        <div className="SDK-section padding-15">
          <SectionHeader name="MicroStrategy SDK and Tools" desc="Customize, extend, integrate, and embed the power and functionality of MicroStrategy analytics into your Web and Mobile applications with MicroStrategy SDK." />
          <div className="buttons">
            {
              sdk.map((data) => {
                return (
                  <SDKCard
                    name={data.name}
                    desc={data.desc}
                    color={data.color}
                    link={data.link}
                  />
                );
              })
            }
          </div>
          <MoreButton name="See more projects on GitHub" link="https://github.com/microstrategy" />
        </div>
        <div className="courses-section padding-15">
          <SectionHeader name="Courses" desc="Discover and learn exciting new technology with MicroStrategy classes" />
          <div className="buttons">
            {
              courses.map((data) => {
                return (
                  <CourseCard
                    name={data.name}
                    src={'./Images/' + data.src}
                    link={data.link}
                  />
                );
              })
            }
          </div>
          <MoreButton name="See more courses" link="https://www.microstrategy.com/en/education" />
        </div>
      </div>
      <div className="footers">
        <Footer />
      </div>
    </div>
  );
}

export default App;
