import React from 'react'
import './CourseCard.css'

interface CourseCardProps {
    link:string,
    src:string,
    name:string
}
function CourseCard(props:CourseCardProps) {
    return (
        <div className="card course-button" onClick={function() {window.open(props.link, "_blank");}}>
            <img src={props.src} alt={props.name} id="course-img"/>
            <h3 className="course-name">{props.name}</h3>
        </div>
    );
}

export default CourseCard
