import React from "react";
import "./SDKCard.css";

interface SDKCardProps {
    name:string,
    desc:string,
    link:string,
    color:string
}

function SDKCard(props:SDKCardProps) {
    return (
        <div className="card sdk-button">
            <div className="sdk-button-content" onClick={() => { window.open(props.link, "_blank") }}>
                <h2 className="sdk-name">{props.name}</h2>
                <hr className="sdk-button-hr" style={{ backgroundColor: props.color}}/>
                <p className="sdk-desc">{props.desc}</p>
            </div>
        </div>
    );
}

export default SDKCard;